import React, { useEffect, useRef } from "react";
import Layout from '../components/layout'
import { graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import CircleLink from "../components/circle-link";
import ContactForm from '../components/contact-form'
import { gsap } from 'gsap'
import utils from "../components/utils";
import ContentGrid from '../components/content-grid'
import { scroll } from "../theme";
import Reviews from "../components/reviews";
import SEO from "../components/seo";
import HomeResources from "../components/home-resources";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";
import WebImage from "../components/image";


export default function IndexPage({ data }) {

  const naomiSectionEl = useRef();
  const naomiTitleEl = useRef();
  const naomiSubEl = useRef();


  const normalPageLoad = (isFast) => {
    utils.resetPage()
    window.locomotiveScroll.stop()

    window.scrollTrigger.create({
      trigger: "#home-about",
      start: "top top+=100px",
      end: "bottom top+=100px",
      scroller: "#scroll-container",
      onEnter() {
        utils.fadeNav(true)
      },
      onEnterBack() {
        utils.fadeNav(true)
      },
      onLeave() {
        utils.fadeNav(false)
      },
      onLeaveBack() {
        utils.fadeNav(false)
      }
    })

    gsap.from(naomiTitleEl.current, {
      scrollTrigger: {
        trigger: naomiSectionEl.current,
        scroller: scroll.container,
        start: "top top+=200"
      },
      duration: 1.5,
      delay: 0.2,
      y: 50,
      opacity: 0,
      ease: "power2.out"
    })
    gsap.from(naomiSubEl.current, {
      scrollTrigger: {
        trigger: naomiSectionEl.current,
        scroller: scroll.container,
        start: "top top+=200"
      },
      duration: 1.5,
      opacity: 0,
      y: 50,
      delay: 0.5,
      ease: "power2.out"
    })

    gsap.to('body', {
      opacity: 1,
      duration: isFast ? 0 : 0.5,
      delay: isFast ? 0 : 1,
      ease: "none",
      onComplete: () => {
        gsap.to('.new-top-font', {
          opacity: 1,
          y: 0,
          duration: isFast ? 0 : 0.5,
          ease: "power2.out"
        })
        gsap.to('#nav', {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
          onComplete: () => {
            window.locomotiveScroll.start()
          }
        })
        window.scrollTrigger.refresh()
        gsap.from("#home-about h2", {
          scrollTrigger: {
            trigger: "#home-about",
            scroller: scroll.container,
            start: "top center"
          },
          duration: 1.5,
          delay: 0.2,
          y: 50,
          opacity: 0,
          ease: "power2.out"
        })
        gsap.from("#home-about .circle-link-container", {
          scrollTrigger: {
            trigger: "#home-about",
            scroller: scroll.container,
            start: "top center"
          },
          duration: 1.5,
          delay: 0.3,
          y: 40,
          opacity: 0,
          ease: "power2.out"
        })
      }
    })
  }

  useEffect(() => {
    window.locomotiveScroll.stop()
    if (document.querySelector('body').classList.contains('loaded-preloader')) {
      // Normal page
      normalPageLoad()
    } else {
      gsap.set('#html-preloader', {
        visibility: 'visible',
      })
      gsap.timeline({
        onComplete: () => {
          // Fade in page (normal page load)
          normalPageLoad(true)
          // Hide preloader
          // gsap.set('#html-preloader', {
          //   visibility: 'hidden'
          // })
        }
      }).to('#html-preloader svg', {
        opacity: 1,
        delay: 1,
        duration: 1,
        ease: "power2.out"
      }).to('#html-preloader', {
        clipPath: `polygon(0 0, 100% 0, 100% 0%, 0 0%)`,
        duration: 1.5,
        delay: 1,
        ease: "power4.out"
      }).to('.new-top-font', {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power2.out"
      }, "-=1.2").to('#nav', {
        opacity: 1,
        duration: 0.7,
        ease: "power2.out"
      }, "-=1.7")
    }
    setTimeout(() => {
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  return (
    <Layout>
      <SEO
        title={data.contentfulPageHome.meta.title}
        description={data.contentfulPageHome.meta.description}
        image={data.contentfulPageHome.meta.image.fluid.src}
      />
      <BackgroundImage fluid={utils.isDesktop() ? data.contentfulPageHome.headerImage.fluid : data.contentfulPageHome.mobileHeaderImage.fluid} backgroundColor={utils.getPurpleHex()} className={'w-full h-screen flex items-center justify-end'}>
        <div className="overflow-hidden w-full lg:w-1/2 px-4 lg:px-12">
          <h1 className="new-top-font font-sc text-lg lg:text-xl transform translate-y-full opacity-0"><span className="pl-4 lg:pl-12">A</span> sensitive, solution-focused<br />approach to family law</h1>
        </div>
      </BackgroundImage>

      <section id="home-about" className="py-60 bg-dark text-sand">
        <div className="container text-center mx-auto">
          <h2 className="text-center font-sailec mx-auto lg:text-lg mb-8" style={{ maxWidth: '626px' }}>We are a highly regarded family law firm specialising in <span className="pretext" data-text="I">divorce</span>,  <span className="pretext" data-text="II">separation</span>,  <span className="pretext" data-text="III">property settlements</span>, <span className="pretext" data-text="IV">parenting disputes</span>, <span className="pretext" data-text="V">child support</span>, <span className="pretext" data-text="VI">domestic violence</span>, <span className="pretext" data-text="VII">pre-nuptial agreements</span>, <span className="pretext" data-text="VIII">adoption</span> and <span className="pretext" data-text="IX">surrogacy</span>.</h2>
          <div className="circle-link-container">
            <CircleLink inverse={true} title="See Services" to="/services" isLink={true} onPress={() => {
              utils.fadeOutFromPurple()
            }} />
          </div>
        </div>
      </section>
      <section ref={naomiSectionEl} className="home-header-top lg:min-h-screen lg:flex">
        <div className="home-header-image flex-0 h-50vh lg:h-screen mobile-landscape-naomi order-1 relative text-center lg:text-left order-2 lg:w-1/2">
          <WebImage backgroundColor={utils.getPurpleHex()} image={data.contentfulPageHome.naomiImage.fluid} innerCssClass="w-full h-full" outerCssClass={`w-full h-full`} />
        </div>
        <div className="flex flex-0 self-stretch items-center justify-left relative order-2 min-h-50vh lg:min-h-screen py-40 p-4 lg:p-12 lg:w-1/2">
          <div>
            <h2 ref={naomiTitleEl} className="text-lg lg:text-xl font-sc capitalize mb-4">Meet Naomi Lewis</h2>
            <div ref={naomiSubEl} className="font-mabry tracking-wider uppercase text-sm">Legal Director and Principal Lawyer</div>
          </div>
          <span className="home-read-more absolute left-4 lg:right-12 bottom-12"><CircleLink title="Read More" to="/about" isLink={true} onPress={() => {
            utils.fadeOutFromPurple()
          }} /></span>
        </div>


      </section>
      {data.contentfulPageHome.contentSections.map((section, index) => <ContentGrid
        index={index}
        title={section.title}
        description={section.shortDescription.shortDescription}
        image={section.image.fluid}
        left={index % 2 !== 0}
      />)}

      <Reviews />
      <HomeResources dropImageMobile={true} index={1} side="right" />
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulPageHome {
      contentSections {
        title
        shortDescription {
          shortDescription
        }
        image {
          fluid(quality: 90, maxHeight: 1080) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      headerImage {
        fluid(quality: 95, maxHeight: 1080) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileHeaderImage {
        fluid(quality: 95, maxHeight: 1080) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      naomiImage {
        fluid(quality: 95, maxHeight: 1080) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      meta {
        title
        description
        image {
          fluid {
            src
          }
        }
      }
    }
  }
`
