import React, { useEffect, useRef, useState } from "react";
import utils from "../components/utils";
import { scroll } from "../theme";
import gsap from "gsap/gsap-core";
import { graphql, useStaticQuery } from 'gatsby'
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";

export default function Reviews() {

  const ANIMATION_DURATION_SECONDS = 0.5

  const data = useStaticQuery(graphql`
    query ReviewsQuery {
      contentfulPageHome {
        reviews {
          text
          name
        }
      }
    }
  `)

  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const nameEl = useRef();
  const textEl = useRef();

  const next = () => {
    if (isAnimating) {
      return
    }
    setIsAnimating(true)

    const nextIndex = currentReviewIndex === data.contentfulPageHome.reviews.length - 1 ? 0 : currentReviewIndex + 1

    gsap.to([nameEl.current, textEl.current], {
      opacity: 0,
      duration: ANIMATION_DURATION_SECONDS,
      ease: "power2.out",
      onComplete: () => {
        setCurrentReviewIndex(nextIndex)
        gsap.to([nameEl.current, textEl.current], {
          opacity: 1,
          duration: ANIMATION_DURATION_SECONDS,
          ease: "power2.out",
          delay: 0.1,
          onComplete: () => {
            setIsAnimating(false)
          }
        })
      }
    })
  }

  const prev = () => {
    if (isAnimating) {
      return
    }
    setIsAnimating(true)

    const previousIndex = currentReviewIndex === 0 ? data.contentfulPageHome.reviews.length - 1 : currentReviewIndex - 1

    gsap.to([nameEl.current, textEl.current], {
      opacity: 0,
      duration: ANIMATION_DURATION_SECONDS,
      ease: "power2.out",
      onComplete: () => {
        setCurrentReviewIndex(previousIndex)
        gsap.to([nameEl.current, textEl.current], {
          opacity: 1,
          duration: ANIMATION_DURATION_SECONDS,
          ease: "power2.out",
          delay: 0.1,
          onComplete: () => {
            setIsAnimating(false)
          }
        })
      }
    })
  }


  useEffect(() => {
    setTimeout(() => {
      window.scrollTrigger.create({
        trigger: "#reviews",
        start: "top top+=100px",
        end: "bottom top+=100px",
        scroller: scroll.container,
        onEnter() {
          utils.fadeNav(true)
        },
        onEnterBack() {
          utils.fadeNav(true)
        },
        onLeave() {
          utils.fadeNav(false)
        },
        onLeaveBack() {
          utils.fadeNav(false)
        }
      })
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  return (
    <div id="reviews" className="h-75vh md-h-75vh bg-dark flex text-sand">
      <div className="container self-stretch flex flex-col justify-between px-0 lg:px-12 p-12">
        <div className="font-mabry uppercase text-sm">Testimonials</div>
        <h2 ref={nameEl} className="font-sailec text-lg max-w-xl mb-8 mt-12 lg:mt-0">
          {data.contentfulPageHome.reviews[currentReviewIndex].text}

        </h2>
        <div ref={textEl} className="max-w-xl">
          <p>
            {data.contentfulPageHome.reviews[currentReviewIndex].name}
          </p>
          <div className="grid grid-cols-2 gap-4 mt-8">
            <button className="text-left font-mabry tracking-wider text-sm uppercase link-generic" onClick={() => {
              prev()
            }}>
              Prev
              </button>
            <button className="text-right font-mabry tracking-wider text-sm uppercase link-generic" onClick={() => {
              next()
            }}>
              Next
              </button>
          </div>
        </div>
      </div>
    </div>
  )
}
