import React, { useEffect, useRef, useState } from "react";
import CircleLink from "./circle-link";
import utils from "../components/utils";
import { window } from "browser-monads";
import WebImage from "./image";
import { useStaticQuery } from "gatsby";
import gsap from "gsap";
import BackgroundImage from "gatsby-background-image";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";
import { scroll } from "../theme";

export default function HomeResources({ index, side, dropImageMobile }) {

  const ANIMATION_DURATION_SECONDS = 0.5

  const titleEl = useRef();
  const publishedByEl = useRef();
  const imageEl = useRef();
  const contentEl = useRef();
  const homeReviewsEl = useRef();

  const [currentBlogIndex, setCurrentBlogIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const data = useStaticQuery(graphql`
    query HomeResourcesQuery {
      
      allContentfulBlogPost(sort: {fields: featured}) {
        nodes {
          title
          author {
            firstName
            lastName
          }
          firstParagraphSummary {
            firstParagraphSummary
          }
          slug
        }
      }
      contentfulPageHome {
        blogImage {
          fluid(quality: 90, maxHeight: 1080) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const posts = data.allContentfulBlogPost.nodes
  const blogImage = data.contentfulPageHome.blogImage.fluid

  const fadeInReviews = () => {
    gsap.to(homeReviewsEl.current, {
      opacity: 1,
      duration: 0.75,
      delay: 0.5,
      ease: "none"
    })
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTrigger.create({
        trigger: homeReviewsEl.current,
        start: "top bottom-=100",
        scroller: scroll.container,
        onEnter: () => {
          fadeInReviews()
        },
        onEnterBack() {
          fadeInReviews()
        },
      })
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  useEffect(() => {
    gsap.to([contentEl.current], {
      opacity: 1,
      duration: ANIMATION_DURATION_SECONDS,
      ease: "power2.out",
      delay: 0,
      onComplete: () => {
        setIsAnimating(false)
      }
    })
  }, [currentBlogIndex])


  const desktopAnimation = (index) => {
    window.locomotiveScroll.stop()
    window.locomotiveScroll.scrollTo(`#post-${index}`, {
      duration: 100
    })
  }

  const next = () => {
    if (isAnimating) {
      return
    }
    setIsAnimating(true)

    const nextIndex = currentBlogIndex === posts.length - 1 ? 0 : currentBlogIndex + 1

    gsap.to([contentEl.current], {
      opacity: 0,
      duration: ANIMATION_DURATION_SECONDS,
      ease: "power2.out",
      onComplete: () => {
        setCurrentBlogIndex(nextIndex)
        setIsAnimating(false)
      }
    })
  }

  const prev = () => {
    if (isAnimating) {
      return
    }
    setIsAnimating(true)

    const previousIndex = currentBlogIndex === 0 ? posts.length - 1 : currentBlogIndex - 1

    gsap.to([contentEl.current], {
      opacity: 0,
      duration: ANIMATION_DURATION_SECONDS,
      ease: "power2.out",
      onComplete: () => {
        setCurrentBlogIndex(previousIndex)
        setIsAnimating(false)
      }
    })
  }

  return (
    <section ref={homeReviewsEl} id={`post-${index}`} className="blog-post lg:min-h-screen lg:flex" style={{
      opacity: 0
    }}>
      <div className="blog-post__details flex flex-0 justify-between flex-col px-0 lg:px-12 p-12 relative w-full lg:w-1/2">
        <div className="font-mabry uppercase tracking-wider text-sm mx-4 lg:mx-0">Articles</div>
        <div ref={contentEl} className="max-w-lg lg:absolute lg:top-1/2 transform lg:-translate-y-1/2 lg:h-auto py-20 lg:py-0 px-4 lg:px-0">
          <h3 className="font-sailec text-lg mb-8" ref={titleEl}>{posts[currentBlogIndex].title}</h3>
          <p className="font-sailec" ref={publishedByEl}>Published by {posts[currentBlogIndex].author.firstName} {posts[currentBlogIndex].author.lastName}</p>
          <CircleLink cssClass="mt-10" title="Read Article" isLink={true} to={`/article/${posts[currentBlogIndex].slug}`} duration={utils.getScrollToDuration()} side={side} onPress={() => {
            utils.isDesktop() ? desktopAnimation(index) : utils.fadeOutFromPurple()
          }} />
        </div>
        <div className="grid grid-cols-3 gap-4 mt-8 px-4 lg:px-0">
          <button className="text-left font-mabry tracking-wider text-sm uppercase link-generic" onClick={() => {
            prev()
          }}>Prev</button>
          <div className="text-center font-mabry tracking-wider text-sm uppercase">{currentBlogIndex + 1}/{posts.length}</div>
          <button className="text-right font-mabry tracking-wider text-sm uppercase link-generic" onClick={() => {
            next()
          }}>Next</button>
        </div>
      </div>
      <WebImage backgroundColor={utils.getSandHex()} image={blogImage} outerCssClass={`blog-post__image flex-0 h-50vh h-mobile-landscape-150vh lg:min-h-screen w-full lg:w-1/2 relative ${dropImageMobile ? 'hidden lg:block' : ''}`} innerCssClass="image-0 absolute top-0 left-0 w-full h-full z-10" />
    </section >
  )
}
