import React, { useEffect, useRef } from "react";
import { scroll } from "../theme";
import gsap from "gsap/gsap-core";
import { window } from "browser-monads";
import CircleLink from "./circle-link";
import utils from "./utils";
import WebImage from "./image";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";

export default function ContentGrid({ left, subtitle, title, description, image, subtext, slug, index, link }) {

  const ref = useRef();
  const titleEl = useRef();
  const subEl = useRef();

  const desktopAnimation = (index) => {
    window.locomotiveScroll.stop()
    window.locomotiveScroll.scrollTo(`#person-${index}`, {
      duration: 100
    })
  }

  useEffect(() => {
    setTimeout(() => {
      gsap.from(titleEl.current, {
        scrollTrigger: {
          trigger: ref.current,
          scroller: scroll.container,
          start: "top top+=200"
        },
        duration: 1.5,
        delay: 0.2,
        y: 50,
        opacity: 0,
        ease: "power2.out"
      })
      gsap.from(subEl.current, {
        scrollTrigger: {
          trigger: ref.current,
          scroller: scroll.container,
          start: "top top+=200"
        },
        duration: 1.5,
        opacity: 0,
        delay: 0.5,
        ease: "power2.out"
      })
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  return (
    <section key={`content-${index}`} id={`person-${index}`} ref={ref} className="content-grid-item bg-sand lg:min-h-screen flex flex-col lg:flex-row">
      <div className={`flex flex-0 flex-col px-4 lg:px-12 p-12 relative w-full lg:w-1/2 sand-section-max-height-fix lg:h-screen ${left ? 'lg:order-2' : ''} ${subtitle ? 'justify-between' : 'justify-between lg:justify-end'}`}>
        {
          subtitle && <span className="font-mabry tracking-wider uppercase text-sm">{subtitle}</span>
        }

        <h3 ref={titleEl} className="will-change-o-transform font-sc capitalize text-lg lg:text-xl lg:absolute md:top-1/2 lg:transform lg:-translate-y-1/2 pt-24 lg:pt-0">{title}</h3>
        <div ref={subEl} className="will-change-opacity max-w-lg">
          {subtext && <p className="font-mabry tracking-wider uppercase text-sm mb-12">{subtext}</p>}
          <p className="font-sailec">{description}</p>
          {
            link && <CircleLink cssClass="mt-4" title="Read More" isLink={true} to={`/about/${slug}`} duration={utils.getScrollToDuration()} onPress={() => {
              utils.isDesktop() ? desktopAnimation(index) : utils.fadeOutFromPurple()
            }} />
          }
        </div>
      </div>
      <WebImage backgroundColor={utils.getPurpleHex()} image={image} innerCssClass="w-full h-full" outerCssClass={`lg:flex-0 h-75vh h-mobile-landscape-150vh lg:min-h-screen w-full lg:w-1/2 ${left ? 'lg:order-1' : ''}`} />
    </section>
  )
}
