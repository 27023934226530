import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import BackgroundImage from 'gatsby-background-image'
import { scroll } from "../theme";
import gsap from "gsap/gsap-core";
import { window } from "browser-monads";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";
import utils from "./utils";

export default function WebImage({ image, innerCssClass, outerCssClass, backgroundColor, onLoad }) {

  const [enteredView, setEnteredView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFadedIn, setIsFadedIn] = useState(false);

  useEffect(() => {
    if (enteredView && !isFadedIn && isLoaded) {
      fadeImage()
    }
  }, [enteredView])

  useEffect(() => {
    if (enteredView && !isFadedIn && isLoaded) {
      fadeImage()
    }
  }, [isLoaded])

  const el = useRef();

  const fadeImage = () => {
    if (!isFadedIn) {
      gsap.to(el.current, {
        opacity: 1,
        duration: 0.75,
        delay: 0.5,
        ease: "none",
        onComplete: () => {
          setIsFadedIn(true)
        }
      })
    }
  }

  const onEnterEvent = (isEnteredView) => {
    fadeImage()
    setEnteredView(isEnteredView)
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTrigger.create({
        trigger: el.current,
        start: "top bottom-=100",
        scroller: scroll.container,
        onEnter: () => {
          if (onEnterEvent) {
            onEnterEvent(true)
          }
        },
        onEnterBack() {
          if (onEnterEvent) {
            onEnterEvent(true)
          }
        },
        onLeave() {
          if (onEnterEvent) {
            onEnterEvent(false)
          }
        },
        onLeaveBack() {
          if (onEnterEvent) {
            onEnterEvent(false)
          }
        }
      })
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])
  return (
    <div ref={el} className={`${outerCssClass} will-change-opacity`}
      style={{
        opacity: 0
      }}
    >
      <BackgroundImage backgroundColor={backgroundColor ? backgroundColor : utils.getPurpleHex()} onLoad={() => {
        if (onLoad) {
          onLoad()
        }
        setIsLoaded(true)
      }} fluid={image} className={`${innerCssClass} web-image-gatsby-container`} />
    </div>
  )
}
